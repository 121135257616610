<template>
  <div>

    <block-where  v-if="!nodata" :rangeYear="rangeYear" :curAreaId="curAreaId" @cid-rangeyear="cidRangeyear"
      @change-areaid="changeAreaid"></block-where>

    <div v-if="nodata">
      <el-result icon="info" title="暂无数据" subTitle="当前城市数据不存在...">
      </el-result>
    </div>
    <div v-else class="report">
      <div class="tablebox">
        <div class="title">{{ tableData.title }}</div>
        <el-table ref="singleTable" @row-click="clickRow" class="table" :data="tableData.table" size="mini"
          :highlight-current-row="true">
          <el-table-column v-for="cell in tableData.header" :key="cell.prop" :prop="cell.prop" :label="cell.label"
            align="center">
          </el-table-column>
        </el-table>
        <div class="footerinfo">
          <time-source :data="{ time: tableData.uptime, source: tableData.source }"></time-source>
        </div>
      </div>
      <div class="piebox">
        <div class="downbox">
          <el-button type="primary" icon="el-icon-download" size="mini" @click.prevent="downloadExcel">导出Excel
          </el-button>
        </div>
        <div class="pie">
          <pie-2 :data="pieData"></pie-2>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
import BlockWhere from "../../components/cityreserch/BlockWhere.vue";
import Pie2 from "../../components/echarts/pie2";
import TimeSource from "../../components/cityreserch/TimeSource.vue";

import { cityYear, gdpData, downloadGdpExcel, gdpRangeYear } from "../../api/econ";
export default {
  name: "VueElementTupaiGdp",
  components: { BlockWhere, TimeSource, Pie2 },
  data() {
    return {
      nodata: false,
      rangeYear: [],
      curAreaId: 0,
      citylist: [],
      tableData: {},
      valueObj: null,
      pieData: {
        title: "",
        main: [],
      },
    };
  },

  mounted() {
    this.getWhere();
  },
  computed: {
    
    //当前选择的id
    block: {
      get() {
        return this.$store.state.block
      },
      set(v) {
        this.$store.commit('changeBlock', v)
      }
    }

  },
  watch: {
    block() {
      this.getWhere();
    },
  },
  methods: {
    //更改当前的区id
    changeAreaid(id) {
   
      this.getCurrentRange({ city_plate_id: id })
    },

    getCurrentRange(params) {
      params.cateid = 1
      gdpRangeYear(params).then(res => {
        this.nodata = false;
        if (parseInt(res.code) === 1001) {
          this.nodata = true;
        }
        this.rangeYear = res.data.rangeYear;
      })
    },

    //下载excel
    downloadExcel() {
      const params = {
        city_plate_id: this.block,
        start_year: this.rangeYear[0],
        end_year: this.rangeYear[1],
      };
      params.cateid = 1

      downloadGdpExcel(params).then((res) => {
        if (res.code === 1001) {
          this.$message("此文档不存在...");
        } else {
          this.download = res.data;
          window.location.href = this.download.fileUrl;

          // this.filesToRar([this.download],this.download.renameFileName)
        }
      });
    },
    //子组建返回的事件
    cidRangeyear(obj) {

      const params = {
        city_plate_id: this.block,
        start_year: obj.startyear,
        end_year: obj.endyear,
      };
      // params.cateid = 1

      this.getGdpData(params);
    },
    //设置当前选中行
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    //点击行事件
    clickRow(row, column, event) {
      console.log("row", row);
      this.valueObj = row;
      this.handlePieData();
    },

    getWhere() {
      const params = { city_plate_id: this.block, cateid: 1 };

      cityYear(params).then((res) => {

        this.nodata = false;
        if (parseInt(res.code) === 1001) {
          this.nodata = true;
        }
        this.citylist = res.data.citylist;
        this.rangeYear = res.data.rangeYear;
        res.data.citylist.every((item) => {
          if (!item.disabled) {
            this.block = item.id;
            return false;
          }
        });

        if (this.rangeYear.length !== 0) {
          const params = {
            city_plate_id: this.block,
            start_year: this.rangeYear[0],
            end_year: this.rangeYear[1],
          };
          this.nodata = false
          this.getGdpData(params);
        } else {
          this.tableData = []
          this.nodata = true
          this.$message('暂无数据')
        }

      });
    },

    getGdpData(params) {
      params.cateid = 1
      gdpData(params).then((res) => {

        this.nodata = false;
        if (parseInt(res.code) === 1001) {
          this.nodata = true;
        }
        this.tableData = res.data;
        this.valueObj = null;
        this.handlePieData();

        this.setCurrent(res.data.table[0]);
      });
    },
    //处理pie数据
    handlePieData() {
      let pieData = {};
      let valueObj = this.valueObj || this.tableData.table[0];
      let arr = [
        {
          name: this.tableData.header[2].label,
          value: valueObj.cell3,
        },
        {
          name: this.tableData.header[3].label,
          value: valueObj.cell4,
        },
        {
          name: this.tableData.header[4].label,
          value: valueObj.cell5,
        },
      ];
      pieData.main = arr;
      pieData.title = valueObj.cell1 + "年度地区产业对比";
      this.pieData = pieData;
    },
  },
};
</script>

<style lang="scss" scoped>
.report {
  display: flex;
  justify-content: space-around;
  align-items: center;

  .tablebox {

    width: 40%;

    .title {
      margin-top: 15px;
      font-size: 18px;
      font-weight: 400;
    }
  }

  .piebox {
    width: 40%;
    display: flex;
    flex-direction: column;

    .downbox {
      text-align: right;
    }

    .pie {}
  }
}
</style>